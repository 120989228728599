<template>
    <h2>Elenco abbonamenti<br/><small>In verde gli abbonamenti validi</small></h2>
    <ul class="list-group">
        <li class="list-group-item" v-bind:style="getStyleAbbonamento(abbonamento)" v-for="abbonamento in socio.abbonamenti" v-bind:key="abbonamento.ID_ABBONAMENTO">
            <strong>{{abbonamento.DESCRIZIONE}}</strong>
            <br/>
            Scadenza: {{filters.formatDate(abbonamento.DATA_SCADENZA)}} - Ingressi: {{abbonamento.TOT}} - Stato: <strong>{{ getDescrizioneStato(abbonamento)}}</strong>
            
        </li>
    </ul>
</template>
<script>
export default {
    name:"Ricevute",
    data:function(){
        return{
            filters:global.filters,
            socio:{}
        }
    },
    methods: {  
        getDescrizioneStato : function(abbonamento) {
            if (abbonamento.VALIDITA_DURATA == 1 && abbonamento.VALIDITA_INGRESSI == 1){
                return "Valido";
            }
            else if (abbonamento.VALIDITA_DURATA == 0){
                return "Scaduto";
            }
            else {
                return "Esaurito";
            }
        },
        getStyleAbbonamento : function(abbonamento){
            if (abbonamento.VALIDITA_DURATA == 1 && abbonamento.VALIDITA_INGRESSI == 1){
                return {background:'#69b034',color:"#fff"};
            }
        }
    },
    computed: {

    },
    created : function() {
        utils.ajax('/deskalo/socio',{}, (response) => {
            this.socio = response.data;
        });
    },
}
</script>