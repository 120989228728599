<template>
    <div style="width:540px;background:#fff;padding-top:1px">
        <div class="upload-div container h-100" style="margin-top:20px;" v-cloak @drop.prevent="addFile" @dragover.prevent >
            <div class="row align-items-center h-100">
                <div class="mx-auto" style="text-align:center">
                    <h5 style="color:#36b9cc;font-weight:bold"><i style="font-size:50px" class="fas fa-cloud-upload-alt"></i><br/><br/>Trascina qui il file<br/><small>oppure</small><br/>fai click su Seleziona file</h5>                
                </div>
            </div>
        </div>
        <div class="col-md-12" style="padding:20px">
            <input style="display:none" type="file" :ref="'input'" @change="addFile($event,1)">
            <button style="margin-top:4px" class="btn btn-info btn-lg btn-block" v-on:click="openDialog()"><i class="far fa-folder-open fa-fw"></i>Seleziona file</button>        
        </div>
        <!--
        <ul v-if="3 == 5">
            <li v-for="file in files" v-bind:key="file">
            {{ file.name }} ({{ file.size | kb }} kb) <button @click="removeFile(file)" title="Remove">X</button>
            </li>
        </ul>-->
    </div>
</template>
<script>
export default {
    name: 'Upload',
    props:{
        url: {
            type: String,
            required: false,
            default: "CORE/SERVER/API/core/basic/upload"
        },
        args:{
            type: Object,
            required: false,
            default: () => {return {}}
        }
    },
    data:function(){
        return{
            files:[],
        }
    },
    methods: {
        openDialog:function() {
            $(this.$refs.input).click()
        },
        addFile(e,fromButton = 0) {
            if (fromButton == 0){
                let droppedFiles = e.dataTransfer.files;
                if(!droppedFiles) return;
                // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
                this.files.push(droppedFiles[0]);            
            }
            else{
                this.files.push(e.target.files[0]);
            }
            this.upload();
        },
        removeFile(file){
            this.files = this.files.filter(f => {
                return f != file;
            });      
        },
        fireEvent(event,res){
            console.log("fire event " + event);
            this.$emit(event,res);
        },
        upload() {
            utils.blockPage();
            let formData = new FormData();
            this.files.forEach((f,x) => {
                formData.append('file'+(x+1), f);
            });
            formData.append('args',JSON.stringify(this.args));
            var authHeader ="Bearer "+utils.TOKEN;      
            var options = {
                headers: {
                    Authorization: authHeader
                },
                method:"POST",
                body:formData
            };
            fetch(utils.BASE_API + this.url, options)
            .then(res => {
                this.files = [];
                console.log(res);
                utils.unblockPage();
                if (res.status == 200){
                    this.fireEvent("onsuccess",res);
                }
                else{
                    this.fireEvent("onerror",res);
                }
            })
            .catch(e => {
                console.log(e);
                //console.error(JSON.stringify(e.message));
                this.files = [];
                utils.unblockPage();
                //utils.alert.warning(res.messaggio);
                //this.fireEvent("onerror",{esito:1,messaggio:e.message});
            });
        },
    },
    computed: {

    }
}
</script>