<template>
     <div>
        <div class="row">
            <div class="col-md-9">
                <div class="card shadow " style="margin-top:20px;">
                    <div class="card-header card-header-background">
                        <h2><i class="fa-fw fal fa-box-full"></i> Pagamento ordine {{ordine.ID_ORDINE}}</h2>
                    </div>
                    <div class="card-body" style="padding:0">
                        <div class="col-md-12" style="margin-top:20px">
                            <div v-if="successPagamento == 1" class="row">
                                <h2 style="text-align:center;width:100%">
                                    <i class="fal fa-smile fa-2x fa-inverse" style="color:#28a745"></i>
                                    <br/>
                                    Complimenti!<br/>Il pagamento del tuo ordine è andato a buon fine!
                                </h2>
                            </div>
                            <div v-if="errorPagamento == 1" class="row">
                                <h2 style="text-align:center;width:100%">
                                    <i class="fal fa-frown fa-2x fa-inverse" style="color:#c91818"></i>
                                    <br/>
                                    Attenzione!<br/>Si è verificato un errore nel pagamento dell'ordine<br/>
                                    <small>{{messaggioErrore}}</small><br/>
                                    <button class="btn btn-info" v-on:click="riprovaPagamento()"><i class="fal fa-sync-alt fa-fw"></i>Riprova</button>
                                </h2>
                            </div>
                            <hr v-if="RICHIEDENTE == 2">
                            <div v-if="RICHIEDENTE == 2 && (successPagamento + errorPagamento == 0)" class="row">
                                <div class="col-md-4" style="font-weight:bold;text-align:right">
                                    <i class="fal fa-store-alt fa-fw"></i> NEGOZIO
                                </div>
                                <div class="col-md-8" style="font-weight:normal">
                                    Nome: <strong>{{negozio.ALIAS}}</strong><br/>
                                    Telefono : {{negozio.TELEFONO}}<br/>
                                    Indirizzo : {{negozio.INDIRIZZO}} - {{negozio.comune.NOME}}
                                </div>
                            </div>
                            <hr v-if="RICHIEDENTE == 2">
                            <div class="row" style="margin-bottom:20px" v-if="(successPagamento + errorPagamento == 0)">
                                <div class="col-md-4" style="font-weight:bold;text-align:right">
                                    <i class="fal fa-euro-sign fa-fw"></i> TOTALE
                                </div>
                                <div class="col-md-8" style="font-weight:normal">
                                    Carrello: € {{filters.formattaImporto(ordine.IMPORTO - ordine.IMPORTO_CONSEGNA)}}<br/>
                                    Spedizione / consegna: € {{filters.formattaImporto(ordine.IMPORTO_CONSEGNA)}}<br/>
                                    Commissioni: € {{filters.formattaImporto(ordine.IMPORTO_COMMISSIONI)}}<br/>
                                    <hr>
                                    <h3><strong>Totale Ordine: € {{filters.formattaImporto(ordine.IMPORTO)}}</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow " style="margin-top:20px;" v-show="showCard == 1" >
                    <div class="card-header card-header-background">
                        <h2><i class="fal fa-credit-card fa-fw"></i> Dati carta di credito</h2>
                    </div>
                    <div class="card-body" style="padding:0">
                        <div class="col-md-12" style="padding:20px">
                            <div class="row pform" style="margin-left:0px;margin-right:0px">
                                <label for="cardholder-name">Titolare</label>
                                <input id="cardholder-name" class="form-control" type="text">
                            </div>
                            <div class="row pform" style="margin-left:0px;margin-right:0px;margin-top:8px">
                                <label for="card-element">
                                Carta di credito o di debito
                                </label>
                                <div id="card-element" class="w-100">
                                <!-- a Stripe Element will be inserted here. -->
                                </div>
                            </div>
                            <div class="row pform" style="margin-left:0px;margin-right:0px">
                                <!-- Used to display form errors -->
                                <div id="card-errors" role="alert"></div>
                            </div>
                            <div id="btn" class="row pform" style="margin-left:0px;margin-right:0px">
                                <button id="card-button" style="margin-top:20px" name="btnSubmit" class="btn btn-success btn-lg btn-block"><i class="fa fa-euro"></i> Effettua il pagamento</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <carrello :modalita="3" :ref="'carrello'"></carrello>
            </div>
        </div>
    </div>

</template>

<script>

import Carrello from '@/components/common/Carrello.vue'


export default {
    name:"Stripe",
    components:{
        Carrello,
        
    },
    props:[],
    data:function(){
        return{
            filters:global.filters,
            chiavePubblica : '',
            urlPagamenti :"",// utils.BASE_API + "ec/stripe/pagamento?FROM=deskalo&",
            stripe :{},
            elements:{},
            cardElement:{},
            ordine : {},
            negozio : {},
            RICHIEDENTE:0,
            successPagamento:0,
            errorPagamento:0,
            messaggioErrore:"",
            showCard:1,
            client_id:""
        }
    },
    methods: {
       getOrdine : function(){
            utils.ajax('/ec/ordine',{ID_ORDINE:this.$root.ordine.ID_ORDINE}, (response) => {
                this.ordine = response.data.ORDINE;
                this.RICHIEDENTE = response.data.RICHIEDENTE;
                this.negozio = response.data.NEGOZIO;
                this.urlPagamenti += "CHIAVE="+this.negozio.STRIPE_KEY+"&ID_ORDINE="+this.ordine.ID_ORDINE;
                this.$refs.carrello.setCarrelloOrdine(this.ordine.quote,this.ordine.IMPORTO);
                this.chiavePubblica = response.data.STRIPE_PUBLIC_KEY;
                this.client_id = this.negozio.STRIPE_CLIENT_ID;
                this.configureStripe();
            });
        },
        riprovaPagamento : function(){
            this.showCard = 1;
            this.messaggioErrore = "";
            this.errorPagamento = 0;
           // this.configureStripe();
            this.$forceUpdate();
        },
        handleServerResponse:function(response) {
            utils.unblockPage();
            if (response.error) {
                console.log(response);
                this.errorPagamento = 1;
                this.messaggioErrore = response.error.hasOwnProperty("message") ? response.error.message : response.error;
                // Show error from server on payment form
                //removeBarError(response.error.hasOwnProperty("message") ? response.error.message : response.error);
            } 
            else if (response.requires_action) {
                console.log("CLIENT_SECRET:" + response.payment_intent_client_secret);
                // Use Stripe.js to handle required card action
                this.stripe.handleCardAction(
                    response.payment_intent_client_secret
                ).then((result) => {
                    if (result.error) {
                        console.log(result);
                        this.errorPagamento = 1;
                        this.messaggioErrore = result.error.message;
                        //removeBarError(result.error.message);
                        // Show error in payment form
                    } 
                    else {
                        utils.blockPage();
                        // The card action has been handled
                        // The PaymentIntent can be confirmed again on the server

                        utils.ajax('ec/stripe/pagamento?ID_ORDINE='+this.ordine.ID_ORDINE,{  payment_intent_id: result.paymentIntent.id }, (json) => {
                            this.handleServerResponse(json);
                            
                            utils.unblockPage();
                        },true,1)


/*
                        fetch(this.urlPagamenti, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ payment_intent_id: result.paymentIntent.id })
                        }).then(function(confirmResult) {
                            console.log(confirmResult);
                            return confirmResult.json();
                        }).then(this.handleServerResponse);*/
                    }
                });
            } 
            else {
                this.successPagamento = 1;            
            }
        },
        configureStripe : function(){
            console.log("*** INIZIALIZZAZIONE STRIPE *** ");
            if (this.negozio.ID_NEGOZIO == 1){
                this.stripe = Stripe(this.chiavePubblica);
            }
            else{
                this.stripe = Stripe(this.chiavePubblica,{stripeAccount:this.client_id});
            }
            this.elements = this.stripe.elements();
            this.cardElement = this.elements.create('card');
            this.cardElement.mount('#card-element');
            var cardholderName = document.getElementById('cardholder-name');
            var cardButton = document.getElementById('card-button');

            cardButton.addEventListener('click', (ev) => {
                if ($('#cardholder-name').val() == ""){
                    alert('Impostare un valore per il campo "Titolare della carta"');
                    return;
                }
                utils.blockPage();
                this.showCard = 0;
                this.stripe.createPaymentMethod('card', this.cardElement, {
                    billing_details: {name: cardholderName.value}
                }).then((result) => {
                    utils.unblockPage();
                    if (result.error) {
                        console.log(result);
                        this.errorPagamento = 1;
                        this.messaggioErrore = result.error.message;
                    // Show error in payment form
                    } else {
                    // Otherwise send paymentMethod.id to your server (see Step 2)
                    utils.blockPage();

                    utils.ajax('ec/stripe/pagamento?ID_ORDINE='+this.ordine.ID_ORDINE,{ payment_method_id: result.paymentMethod.id }, (json) => {
                        this.handleServerResponse(json);
                        console.log(json);
                        if (json.hasOwnProperty("success") && json.success == true){
                            this.successPagamento = 1;
                        }/*
                        else{
                            this.errorPagamento = 1;
                        }*/
                        utils.unblockPage();
                    },true,1)

/*
                    fetch(this.urlPagamenti, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ payment_method_id: result.paymentMethod.id })
                    }).then((result) =>{
                        // Handle server response (see Step 3)
                        result.json().then((json) => {
                            this.handleServerResponse(json);
                            console.log(json);
                            if (json.hasOwnProperty("success") && json.success == true){
                                this.successPagamento = 1;
                            }
                            else{
                                this.errorPagamento = 1;
                            }
                            utils.unblockPage();
                        })
                    });*/
                    }
                });
            });
            $('.__PrivateStripeElement').css("margin-top","2px");
        },
        includeStripe( URL, callback ){
            let documentTag = document, tag = 'script',
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0];
            object.src = '//' + URL;
            if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
            scriptTag.parentNode.insertBefore(object, scriptTag);
        },

    },
    computed: {
        
    },
    mounted(){
        this.includeStripe('js.stripe.com/v3/', function(){
            this.getOrdine();
        }.bind(this) );
    },
    created:function(){
        //this.getOrdine();
    },
    
}
</script>