<template>
    <div>
        <div class="row">
            <div class="col-lg-9">
                <div class="card shadow " style="margin-top:20px;">
                    <div class="card-header card-header-background">
                        <h2><i class="fal fa-fw fa-euro-sign"></i> Il tuo Ordine</h2>
                    </div>
                    <div class="card-body" style="padding:0">
                        <div class="col-md-12">
                            <div class="row"> 
                                <div class="col-md-12" style="margin-top: 20px;margin-bottom: 14px;margin-left:-8px;height: 35px;">
                                    <h4 style=";color:#4ba7f4;margin-bottom: 0px;">
                                    <span class="fa-stack fa-2x" style="font-size:24px">
                                        <i class="fas fa-square fa-stack-2x"></i>
                                        <i class="fal fa-envelope  fa-stack-1x fa-inverse"></i>
                                    </span>
                                    Dati di contatto
                                    </h4><br/>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Email</label>
                                    <input type="text" class="form-control form-control-sm" v-model="ordine.EMAIL">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Telefono</label>
                                    <input type="text" class="form-control form-control-sm" v-model="ordine.TELEFONO">
                                </div>
                            </div>
                            <hr/>

                            <div class="row"> 
                                <div class="col-md-12" style="margin-top: 20px;margin-bottom: 14px;margin-left:-8px;height: 35px;">
                                    <h4 style=";color:#4ba7f4;margin-bottom: 0px;">
                                        <span class="fa-stack fa-2x" style="font-size:24px">
                                            <i class="fas fa-square fa-stack-2x"></i>
                                            <i class="fal fa-euro-sign fa-stack-1x fa-inverse"></i>
                                        </span>
                                        Pagamento e altre opzioni
                                    </h4><br/>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Modalità di pagamento</label>
                                    <select  :disabled="true" class="form-control form-control-sm" v-model="ordine.FK_ID_MODALITA_PAGAMENTO">
                                        <option value="1">Pagamento con carta di Credito</option>
                                    </select>                                
                                </div>
                                <div class="form-group col-md-8">
                                    <label>Note</label>
                                    <textarea type="text" class="form-control form-control-sm" v-model="ordine.NOTE"></textarea>
                                </div>
                            </div>
                            <button class="btn btn-success btn btn-block btn-lg" v-on:click="booking()" style="margin-bottom:20px"><i class="fal fa-check"></i>Avanti</button>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <Carrello :modalita="1" :noq="1"></Carrello>
            </div>
        </div>
    </div>
</template>
<script>

import Carrello from '@/components/common/Carrello.vue'


export default {
    name:"Checkout",
    components:{
        Carrello
    },props:[],
    data:function(){
        return{
            ordine:{FK_ID_MODALITA_CONSEGNA:1,ID_ORDINE:0,DATA_RICHIESTA_CONSEGNA:'',FK_ID_INDIRIZZO_PRECEDENTE:0,FK_ID_MODALITA_PAGAMENTO:1},
            arrayGiorniConsegna: new Array(),
            arrayComuniConsegna: new Array(),
            arrayIndirizziConsegna : new Array(),
            pagamentoOnlineAbilitato : 0,
            consegnaDomicilioAbilitata: 0,
        }
    },
    methods: {
        dataConsegnaChangeHandler : function(){
            this.ordine.ORA_RICHIESTA_CONSEGNA = "";
        },
        booking : function(){
            utils.ajax('deskalo/checkout/booking',this.ordine, (response) => {
                if (response.esito == 0){
                    this.$root.token_ordine = response.data.token;
                    global.router.push("Confirm");
                }
            });
        },
        indirizzoChangeHandler(){
            if (this.ordine.FK_ID_INDIRIZZO_PRECEDENTE > 0){
                var indirizzo = utils.array.select(this.arrayIndirizziConsegna,"ID_INDIRIZZO",this.ordine.FK_ID_INDIRIZZO_PRECEDENTE,1)[0];
                this.ordine.FK_ID_COMUNE_CONSEGNA = indirizzo.FK_ID_COMUNE;
                this.ordine.INDIRIZZO = indirizzo.INDIRIZZO;
                this.ordine.NOTE_CONSEGNA = indirizzo.NOTE;
            }
            else{
                this.ordine.FK_ID_COMUNE_CONSEGNA = 0;
                this.ordine.INDIRIZZO = "";
                this.ordine.NOTE_CONSEGNA = "";
            }
        }
    },
    computed: {
        slotConsegna : function(){
            if (this.ordine.DATA_RICHIESTA_CONSEGNA != ''){
                var giorno = utils.array.select(this.arrayGiorniConsegna,'DATA',this.ordine.DATA_RICHIESTA_CONSEGNA,1)[0];
                if (giorno != undefined){
                    return giorno.ARRAY_SLOT;
                }
            }
            return new Array();
        }
    },
    created : function(){
        this.ordine.EMAIL = utils.clone(this.$root.utente.EMAIL);
        this.ordine.TELEFONO = "-";
        if (this.$root.DESKALO_SOCIO_SELEZIONATO != undefined && this.$root.DESKALO_SOCIO_SELEZIONATO.TELEFONO != "") {
            this.ordine.TELEFONO = this.$root.DESKALO_SOCIO_SELEZIONATO.TELEFONO;
        }
        utils.ajax('/ec/checkout/startup',{}, (response) => {
            this.arrayGiorniConsegna = response.data.GIORNI;
            this.arrayComuniConsegna = response.data.COMUNI;
            this.arrayIndirizziConsegna = response.data.INDIRIZZI;
            this.consegnaDomicilioAbilitata = response.data.CONSEGNA_DOMICILIO_ABILITATA;
            if (this.consegnaDomicilioAbilitata == 0){
                this.ordine.FK_ID_MODALITA_CONSEGNA = 1;
            }
            this.pagamentoOnlineAbilitato = response.data.PAGAMENTO_ONLINE_ABILITATO;
            this.ordine.FK_ID_MODALITA_PAGAMENTO = 1;
            /*if (this.pagamentoOnlineAbilitato == 0){
                this.ordine.FK_ID_MODALITA_PAGAMENTO = 2;
            }*/
        });
    },
}
</script>