<template>
  <div>
    <select class="form-control"  :name="name" :disabled="disabled" :required="required"></select>
  </div>
</template>

<script>

export default {
  name: 'Select2',
  data() {
    return {
      select2: null
    };
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: [String, Array, Number], // previously was `value: String`
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      default: () => {}
    },
    opzioni:{
        type:Object,
        default: () =>{}
    },
    api : {
        type : String,
        default:null
    },
    apiselected:{
        type : String,
        default : null
    }
  },
  watch: {
    options: {
      handler(val,valueOld) {
         // console.log(val,valueOld);
        this.setOption(val);
      },
      deep: true
    },
    modelValue: {
      handler(val) {
        //console.log("change");
        this.setValue(val);
      },
      deep: true
    },
  },
  methods: {
    setOption(val = []) {
      this.select2.empty();
      this.select2.select2({
        placeholder: this.placeholder,
        ...this.settings,
        data: val
      });
      this.setValue(this.modelValue);
    },
    setValue(val) {
        if (val instanceof Array) {
            this.select2.val([...val]);
            this.select2.trigger('change');

        } 
        else {
          if (this.apiselected != null && val > 0){
              global.utils.ajax(this.apiselected,{q:val}, (response) => {
                   if (response.hasOwnProperty('data')){
                        $(this.select2).select2("trigger", "select", {
                            data: { id: val,text:response.data.text }
                        });   
                        this.$forceUpdate();                         
                    }
              },false);
          }
          else{
            this.select2.val([val]);
            this.select2.trigger('change');
          }
      }
    }
  },
  mounted() {
      var opz = {};
      if (this.api != null){
          opz.ajax = {
            url: () => {
                return global.utils.BASE_API + this.api
            },
            minimumInputLength:2,
            quietMillis: 700,
            dataType: 'json',
            cache: false,
            method:'POST',
            processResults:  (data,params) => {
                this.items = data.data;
                return {results:data.data};
            }
        }
    };
    let mergedSettings = {...this.settings, ...opz};
    mergedSettings.multiple = this.multiple;
    mergedSettings.allowClear = true;
    mergedSettings.minimumInputLength = 2;
    this.select2 = global.$(this.$el)
      .find('select')
      .select2({
        placeholder: this.placeholder,
        ...mergedSettings,
        data: this.options
      })
      .on('select2:select select2:unselect', ev => {
       // console.log("selezionato");
        this.$emit('update:modelValue', this.select2.val());
        //this.$emit('select', ev['params']['data']);
      });
    this.setValue(this.value);
  },
  beforeUnmount() {
    this.select2.select2('destroy');
  },
};
</script>