<template>
    <div class="row" style="width:100%">
        <div class="form-group col-md-2" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Cognome <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <input autocomplete="off" type="text" class="form-control " placeholder="Cognome" v-model="persona.COGNOME">
        </div> 
        <div class="form-group col-md-2" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Nome <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <input autocomplete="off" type="text" class="form-control" placeholder="Nome" v-model="persona.NOME">
        </div>
        <div class="form-group col-md-2" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Sesso <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <select class="form-control " v-model="persona.SESSO">
                <option value="1">MASCHIO</option>
                <option value="2">FEMMINA</option>
            </select>
        </div>  
        <div class="form-group col-md-2" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Giorno nascita<i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <select class="form-control" v-model="persona.GIORNO_NASCITA">
                <option v-for="i in 31" v-bind:key="i">{{i}}</option>
            </select>
        </div>     
        <div class="form-group col-md-2" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Mese nascita<i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <select class="form-control" v-model="persona.MESE_NASCITA">
                <option v-for="i in 12" v-bind:key="i" v-bind:value="i">{{getMese(i)}}</option>
            </select>
        </div>     
        <div class="form-group col-md-2" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Anno nascita<i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <select class="form-control" v-model="persona.ANNO_NASCITA">
                <option v-for="anno in getAnni()" v-bind:key="anno">{{anno}}</option>
            </select>
        </div>        
    
        <div class="form-group col-md-3" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Provincia di nascita<i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <select class="form-control"  v-model="persona.FK_ID_PROVINCIA_NASCITA">
                <option value="-1000">Estero</option>
                <option v-for="provincia in arrayProvince" v-bind:value="provincia.ID_PROVINCIA" v-bind:key="provincia.ID_PROVINCIA">{{provincia.NOME}}</option>
            </select>
        </div>
        <div class="form-group col-md-3" style="margin-bottom:.50rem">
            <label  style="color:#008a59;font-weight:bold">Comune di nascita<i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <select class="form-control" v-model="persona.FK_ID_COMUNE_NASCITA" :disabled="!persona.FK_ID_PROVINCIA_NASCITA > 0">
                <option v-for="comune in getComuni('FK_ID_PROVINCIA_NASCITA')" v-bind:value="comune.ID_COMUNE" v-bind:key="comune.ID_COMUNE">{{comune.NOME}}</option>
            </select>
        </div>
        <div class="form-group col-md-3" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Codice Fiscale<i  class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <input autocomplete="off" type="text" class="form-control " placeholder="Codice fiscale" v-model="persona.CODICE_FISCALE">
        </div>   
        <div class="form-group col-md-3" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Telefono<i  class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <input autocomplete="off" type="text" class="form-control " placeholder="Telefono" v-model="persona.TELEFONO">
        </div>   
    
        <div class="form-group col-md-5" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Indirizzo</label>
            <input autocomplete="off" type="text" class="form-control " placeholder="Indirizzo" v-model="persona.INDIRIZZO">
        </div>  
        
        <div class="form-group col-md-3" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Provincia di residenza<i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <select class="form-control " v-model="persona.FK_ID_PROVINCIA_RESIDENZA">
                <option value="-1000">Estero</option>
                <option v-for="provincia in arrayProvince" v-bind:value="provincia.ID_PROVINCIA" v-bind:key="provincia.ID_PROVINCIA">{{provincia.NOME}}</option>
            </select>
        </div>
        <div class="form-group col-md-3" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Comune di residenza<i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <select class="form-control " v-model="persona.FK_ID_COMUNE_RESIDENZA" :disabled="!persona.FK_ID_PROVINCIA_RESIDENZA > 0">
                <option v-for="comune in getComuni('FK_ID_PROVINCIA_RESIDENZA')" v-bind:value="comune.ID_COMUNE" v-bind:key="comune.ID_COMUNE">{{comune.NOME}}</option>
            </select>
        </div>

        <div class="form-group col-md-1" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold"><i class="fal fa-asterisk" style="color:#1cc88a"></i>Cap</label>
            <input autocomplete="off" type="text" class="form-control " placeholder="Cap" v-model="persona.CAP">
        </div> 

       

    </div>
</template>
<script>

import { computed } from 'vue'


export default {
    name: 'PersonaFisica',
 
    props: {
        modelValue: {
            type: Object,
            default: () => ({}),
        },
        co:{
            type:Array,
        }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const persona = computed({
            get: () => props.modelValue,
            set: (val) => emit('update:modelValue', val),
        });
        return { persona };
    },
    data:function(){
        return{
            arrayRegioni : new Array(),
            arrayProvince: new Array(),
            arrayComuni : new Array(),
        }
    },
    methods: {
        setCap(item){
            console.log("SET CAP");
            if (this.persona.CAP == "" || (this.persona.CAP != item.CAP && item.CAP.indexOf("x") == -1)){
                this.persona.CAP = item.CAP;                
            }       
        },
        calcolaCodiceFiscale : function(comune){
            var date = moment(this.persona.DATA_NASCITA,"DD/MM/YYYY",true);
            if (this.persona.SESSO > 0 && this.persona.COGNOME != "" && this.persona.NOME != "" && date.isValid() && this.persona.FK_ID_COMUNE_NASCITA > 0 && comune.ID_COMUNE != 8094){
                date = new Date(date._d);
                //giorno,mese,anno,cognome,nome,sesso,comune
                var codiceFiscale = utils.calcolaCodiceFiscale(date.getDate(),date.getMonth() + 1,date.getFullYear(),this.persona.COGNOME,this.persona.NOME,this.persona.SESSO,comune.COD_FISCO);
                codiceFiscale = codiceFiscale.toUpperCase();
                if (codiceFiscale != this.persona.CODICE_FISCALE){
                    this.persona.CODICE_FISCALE = codiceFiscale;
                    utils.alert.info("Codice fiscale calcolato automaticamente");
                }
            }
        },
        getMese : function(i){
            return utils.getMese(i).DESCRIZIONE;
        },
        getAnni : function(){
            var arr = new Array();
            var currentYear = new Date().getFullYear() + 1;
            for (var i = 1900 ; i < currentYear ; i++){
                arr.push(i);
            }   
            return arr;
        },
        getComuni : function(campo){
            var arr = utils.array.select(this.arrayComuni,"FK_ID_PROVINCIA",this.persona[campo]);
            return arr;
        }
        
    },
    computed: {
    
    },
    watch: {
        
    },
    created : function(){
        utils.ajax('deskalo/geo',{}, (response) => {
            if (response.esito == 0){
                this.arrayProvince = response.data.PROVINCE.data;
                this.arrayComuni = global.utils.getArrayFromJSON(response.data.COMUNI.arrayCampi,response.data.COMUNI.arrayValori);
            }
        });
        
    }
}

</script>