<template>
    <h2 v-show="status == 0">Nuova anagrafica<br/><small>La richiesta sarà soggetta ad approvazione da parte della Segreteria</small></h2>
    <div style="margin-top:20px" v-show="status == 0">
        <PersonaFisicaMobile v-model="socio.persona"></PersonaFisicaMobile>
        <div class="row" style="width:100%">
            <div class="form-group col-md-4" style="margin-bottom:.50rem">
                <label style="color:#008a59;font-weight:bold">Cognome genitore</label>
                <input autocomplete="off" type="text" class="form-control " placeholder="Cognome genitore" v-model="socio.COGNOME_GENITORE">
            </div>  

            <div class="form-group col-md-4" style="margin-bottom:.50rem">
                <label style="color:#008a59;font-weight:bold">Nome genitore</label>
                <input autocomplete="off" type="text" class="form-control " placeholder="Nome genitore" v-model="socio.NOME_GENITORE">
            </div>  

            <div class="form-group col-md-4" style="margin-bottom:.50rem">
                <label style="color:#008a59;font-weight:bold">CF Genitore</label>
                <input autocomplete="off" type="text" class="form-control " placeholder="Codice fiscale genitore" v-model="socio.CF_GEN">
            </div>  
            <div class="form-group col-md-12" style="margin-bottom:.50rem">
                <label style="color:#008a59;font-weight:bold">Note - richieste particolari</label>
                <textarea autocomplete="off" type="text" class="form-control " placeholder="Utilizzare questo campo per eventuali note/richieste" v-model="socio.NOTE"></textarea>
            </div>             
        </div>
        <div class="row" style="width:100%">
            <button class="btn btn-success btn-lg btn-block" @click="generaRichiesta()" style="margin-bottom: 20px; margin-left: 13px; margin-right: 13px;  margin-top: 20px;"><i class="fal fa-check"></i> Invia richiesta</button>
        </div>        
    </div>
    <div style="margin-top:20px" v-show="status == 1">
        <div class="row">
          <h2 style="text-align:center;width:100%">
                <i class="fal fa-smile fa-4x fa-inverse" style="color:#28a745"></i>
                <br/>
                Complimenti!<br/>La richiesta di inserimento di una nuova anagrafica è andata a buon fine!<br/>Riceverai una mail di conferma dalla Segreteria una volta approvata la richiesta
                <br/>
                <br/>
                <button v-on:click="dashboard()" class="btn btn-success btn-lg">Torna alla home</button>
            </h2>
        </div>
    </div>
    <div style="margin-top:20px" v-show="status == 2">
        <div class="row">
          <h2 style="text-align:center;width:100%">
                <i class="fal fa-frown fa-4x fa-inverse" style="color:#e74a3b"></i>
                <br/>
                Attenzione!<br/>Esiste già una richiesta di questo tipo in attesa di approvazione - Attendere l'approvazione della stessa o contattare la segreteria
                <br/>
                <br/>
                <button v-on:click="dashboard()" class="btn btn-success btn-lg">Torna alla home</button>
            </h2>
        </div>
    </div>
</template>

<script>
import PersonaFisicaMobile from '@/components/common/PersonaFisicaMobile.vue'


export default {
    name:"Anagrafica",
    components:{
        PersonaFisicaMobile
    },
    data:function(){
        return{
            socio:{persona:{}},
            status:-1
        }
    },
    methods:{
        generaRichiesta : function(){
            utils.ajax('deskalo/socio/add',{SOCIO:this.socio}, (response) => {
                if (response.esito == 0){
                    this.status = 1;
                }
            });
        },
        dashboard : function(){
            global.router.push("/");
        }
    },
    created : function(){
        utils.ajax('deskalo/canaddsocio',{}, (response) => {
            if (response.esito == 0){
                this.status = 0;
            }
            else{
                alert(response.messaggio);
                this.status = 2;
            }
        },true,1);
        
    }
}
</script>