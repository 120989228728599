<template>
    <div>
        <div class="row" v-if="model != undefined">
            <!-- TYPE,CLASSE,CLASSE_CTRL,LABEL,VMODEL,OPTIONS,STYLE -->
            <!-- PROPRIETA' DINAMICHE -->
            <div v-for="campo in campi" v-bind:class="campo.CLASSE" v-bind:key="campo.ID_CAMPO" style="margin-top:5px;margin-bottom:10px">
                <label v-if="campo.TYPE != 'BUTTON'">{{campo.LABEL}}</label>
                <IControl :campo="campo"  v-model="model[campo.VMODEL]"></IControl>
                <button v-bind:class="campo.CLASSE_CTRL" v-bind:style="campo.STYLE" v-if="campo.TYPE=='BUTTON'" v-on:click="emit(campo.VMODEL)" v-html="campo.LABEL"></button>
            </div>
            <!-- PROPRIETA' DINAMICHE -->
        </div>
    </div>
</template>
<script>
import { computed } from 'vue'
import IControl from '@/components/utils/IControl.vue'


export default {
    name:"IForm",
    components:{
        IControl
    },
    props: {
      modelValue: {
          type: Object,
          default: () => ({}),
      },
      campi : {
          type : Array
      }
    },
    emits: ['update:modelValue'],
        setup(props, { emit }) {
        const model = computed({
            get: () => {console.log(props); return props.modelValue},
            set: (val) => emit('update:modelValue', val),
        });
        return { model };
    },
    data:function(){
        return{
        }
    },
}
</script>