<template>
    <h2>Elenco richieste</h2>
    <ul class="list-group">
        <li class="list-group-item"  v-for="richiesta in arrayRichieste" v-bind:key="richiesta.ID_RICHIESTA" v-on:click="openMessaggi(richiesta)">
            <div class="col-3 p-0">                 
                <span class="badge badge-info fa-2x" v-if="richiesta.FK_ID_STATO == 0"><i class="fal fa-lock-open-alt "></i></span>
                <span class="badge badge-danger  fa-2x" v-else><i class="fal fa-lock-alt  fa-2x"></i> </span>
            </div>
            <div class="col-9 p-0">
                <span>{{richiesta.OGGETTO}}</span><br/>
                Stato: {{richiesta.FK_ID_STATO == 0 ? 'APERTA' : 'CHIUSA'}}<br/>
                <span><small v-if="richiesta.NON_LETTI > 0"><strong>Nuovi messaggi dalla segreteria</strong></small><small v-else>Non ci sono nuovi messaggi</small></span>          
             </div>
        </li>
    </ul>

    <div class="modal fade" id="popUpMessaggi"  role="dialog"  aria-modal="true" >
        <div class="modal-dialog modal-lg" role="document" >
            <div class="modal-content">
                <div class="modal-header modal-header-success">
                    <h5 class="modal-title"><i class="fal fa-euro"></i> Cronologia messaggi</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">x
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="list-group">
                        <li class="list-group-item" v-for="messaggio in arrayMessaggi" v-bind:key="messaggio.ID_MESSAGGIO">
                            <label style="font-weight:bold"><i class="fal fa-calendar"></i> <span style="font-weight: bold;" class="text-primary">{{getUtente(messaggio)}}</span><br/><small><strong>{{filters.formatDateTime(messaggio.DATA_MESSAGGIO)}}</strong></small></label><br/>
                            {{messaggio.TESTO_MESSAGGIO}} 
                            
                        </li>
                        <button class="btn btn-success float-right" style="margin-top:20px" v-on:click="openMessaggio()" v-if="richiestaSelezionata.FK_ID_STATO == 0"><i class="fal fa-comment-plus"></i> Aggiungi una risposta</button>
                    </ul>                        
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="popUpMessaggio"  role="dialog"  aria-modal="true" >
        <div class="modal-dialog modal-lg" role="document" >
            <div class="modal-content">
                <div class="modal-header modal-header-success">
                    <h5 class="modal-title"><i class="fal fa-comment-plus"></i> Risposta</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>Testo risposta</label><br/>
                        <textarea class="form-control "  autocomplete="off"  placeholder="Teso della risposta" type="text"  v-model="messaggioSelezionato.TESTO_MESSAGGIO"></textarea>
                    </div>      
                </div>
                <div class="modal-footer">
                    <button  class="btn btn-success btn-lg btn-block" v-on:click="inviaRisposta()" ><i class="fal fa-check"></i> Invia risposta</button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    name:"Richieste",
    data:function(){
        return {
            filters:global.filters,
            arrayRichieste:new Array(),
            arrayMessaggi : new Array(),
            richiestaSelezionata:{},
            messaggioSelezionato : {}
        }
    },
    methods:{
        getRichieste:function(){            
            utils.ajax('deskalo/richieste',{}, (response) => {
                this.arrayRichieste = response.data;
            });
        },
        openMessaggi : function(richiesta){
            this.richiestaSelezionata = utils.clone(richiesta);
            utils.ajax('as/richiesta',{ID_RICHIESTA:richiesta.ID_RICHIESTA}, (response) => {
                this.arrayMessaggi = response.data.messaggi;
                utils.ajax('as/richiesta/messaggio/letto',{ID_RICHIESTA:richiesta.ID_RICHIESTA}, (response) => {                    
                    var modal = $('#popUpMessaggi');
                    modal.modal('show');
                });
            });
        },
        getUtente : function(messaggio){
            if (messaggio.FK_ID_UTENTE == this.$root.utente.ID_UTENTE){
                return "TU";
            }
            else{
                return "SEGRETERIA";
            }
        },
        openMessaggio : function(){
            this.messaggioSelezionato = {FK_ID_RICHIESTA:this.richiestaSelezionata.ID_RICHIESTA};
            var modal = $('#popUpMessaggio');
            modal.modal('show');
        },
        inviaRisposta : function(){                
            utils.ajax('as/richiesta/messaggio/add',this.messaggioSelezionato, (response) => {
                    utils.ajax('as/richiesta',{ID_RICHIESTA:this.richiestaSelezionata.ID_RICHIESTA}, (response) => {
                    this.arrayMessaggi = response.data.messaggi;
                    var modal = $('#popUpMessaggio');
                    modal.modal('hide');
                    });
            });                
        }     
    },
    created : function(){
        this.getRichieste();
    }
}
</script>